import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'ez-email-input',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './email-input.component.html',
  styleUrl: './email-input.component.scss',
})
export class EmailInputComponent implements OnInit {
  @Input() isDisabled = false;
  @Input() name: string;
  @Input() controlName: string;
  form: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective) {}

  get control(): AbstractControl {
    return this.form.get(this.controlName)!;
  }

  hasError(): boolean {
    const control = this.control;
    return control && control.invalid && (control.dirty || control.touched);
  }

  getErrorMessage(): string {
    const control = this.control;
    if (control.errors?.['email']) {
      return 'Fehler: Die eingegebene E-Mail ist fehlerhaft. Bitte überprüfen Sie Ihre Eingabe.';
    } else {
      return 'Fehler: Dies ist ein Pflichtfeld. Wir benötigen Ihre E-Mail-Adresse, um Ihnen vorab die nötigen Informationen für den Vertragsabschluss zustellen zu können.';
    }
  }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control;
  }
}
