export const feeInfoText =
  'Bei der Bargeldauszahlung an einem Geldautomaten oder bei der Zahlung an einer Verkaufsstelle, innerhalb des Europäischen Wirtschaftsraums (EWR), in einer von Euro abweichenden EWR-Währung mit der oben beantragten Mastercard oder Visa Card (Debit- oder Kreditkarte), wird mich die Bank über ein ggf. anfallendes Währungsumrechnungsentgelt als prozentualen Aufschlag auf den letzten verfügbaren Euro-Referenzwechselkurs der Europäischen Zentralbank (EZB) kostenlos informieren (also z. B. bei der Abhebung Polnischer Zloty an einem Geldautomaten in Polen).';

type ExpandableText = {
  expanded: string;
  alt: string;
  preview: string;
};

export const sepaText: ExpandableText = {
  expanded:
    'Ich ermächtige die Volksbank Raiffeisenbank, wiederkehrende Zahlungen von meinem/unserem Konto mittels Lastschrift einzuziehen.Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.',
  alt: 'Klicken Sie dieses Quadrat zur Erteilung Ihres Sepa-Mandats unter dem angezeigten Verrechnungskonto an',
  preview: 'Ich ermächtige die Volksbank Raiffeisenbank,...',
};

export const schufaText: ExpandableText = {
  expanded:
    'Die Bank und der Herausgeber der Mastercard/Visa Card Kreditkarte (nachfolgend kurz „Karte“) übermitteln im Rahmen dieses Vertragsverhältnisses erhobene personenbezogene Daten über die Beantragung, die Durchführung und Beendigung dieser Geschäftsbeziehung sowie Daten über nicht vertragsgemäßes Verhalten oder betrügerisches Verhalten an die SCHUFA Holding AG, Kormoranweg 5, 65201 Wiesbaden. Rechtsgrundlagen dieser Übermittlungen sind Art. 6 Abs. 1 Buchstabe b und Art. 6 Abs. 1 Buchstabe f der Datenschutz-Grundverordnung (DSGVO). Übermittlungen auf der Grundlage von Art. 6 Abs. 1 Buchstabe f DSGVO dürfen nur erfolgen, soweit dies zur Wahrung berechtigter Interessen der Bank, des Herausgebers oder Dritter erforderlich ist und nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen. Der Datenaustausch mit der SCHUFA dient auch der Erfüllung gesetzlicher Pflichten zur Durchführung von Kreditwürdigkeitsprüfungen von Kunden (§ 505a des Bürgerlichen Gesetzbuchs, § 18a des Kreditwesengesetzes). Der Kunde befreit die Bank und den Herausgeber der Karte insoweit auch vom Bankgeheimnis. Die SCHUFA verarbeitet die erhaltenen Daten und verwendet sie auch zum Zwecke der Profilbildung (Scoring), um ihren Vertragspartnern im Europäischen Wirtschaftsraum und in der Schweiz sowie ggf. weiteren Drittländern (sofern zu diesen ein Angemessenheitsbeschluss der Europäischen Kommission besteht, oder Standardvertragsklauseln vereinbart wurden, die unter www.schufa.de eingesehen werden können) Informationen unter anderem zur Beurteilung der Kreditwürdigkeit von natürlichen Personen zu geben. Nähere Informationen zur Tätigkeit der SCHUFA können dem SCHUFA-Informationsblatt nach Art. 14 DSGVO (siehe Anlage) entnommen oder online unter www.schufa.de/datenschutz eingesehen werden.',
  alt: '',
  preview: 'Die Bank und der Herausgeber der...',
};

export const moneyLaunderingText: ExpandableText = {
  expanded: 'Als Antragsteller handle ich im eigenen wirtschaftlichen Interesse und nicht auf fremde Veranlassung.',
  alt: 'Klicken Sie dieses Quadrat für die Angabe nach §3 Geldwäschegesetz an',
  preview: 'Als Antragsteller handle...',
};

export const bankInfoText: ExpandableText = {
  expanded:
    'Ich ermächtige meine Bank ausdrücklich, der DZ BANK allgemein gehaltene bankübliche Auskünfte, die im Zusammenhang mit der Ausstellung und Benutzung der Karte erforderlich sind, sowie Auskünfte (einschließlich der Übersendung von Kopien der erhaltenen Unterlagen), welche zur Erfüllung der gesetzlichen Pflichten nach Geldwäschegesetz und zur Legitimation nach § 154 Abgabenordnung (AO) erforderlich sind, zu erteilen. Diese Ermächtigung gilt bis auf Widerruf. Die Bank darf der DZ BANK als Herausgeber der Karte kreditrelevante Informationen zur Verfügung stellen, soweit dies im Zusammenhang mit der Zahlungsfunktion der Karte erforderlich ist, insbesondere die Höhe des Kreditrahmens und des in Anspruch genommenen Kredits.',
  preview: 'Ich ermächtige meine Bank ausdrücklich,...',
  alt: 'Klicken Sie dieses Quadrat für die Ermächtigung zur Erteilung einer Bankauskunft an',
};
