import { Component } from '@angular/core';

@Component({
  selector: 'ez-already-sent',
  standalone: true,
  imports: [],
  templateUrl: './already-sent.component.html',
  styleUrl: './already-sent.component.scss',
})
export class AlreadySentComponent {}
