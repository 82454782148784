<div id="accountInfo" class="bordered flex-centered">
  <img
    id="vbLogo-small"
    src="https://acruvglobalassetstack-assetbucket1d025086-18mcf4ueedojk.s3.eu-central-1.amazonaws.com/campaign-assets/dz-bank/logos/VR_Logo_RGB.png"
    alt="Logo der VR Banken"
  />
  <div class="flex-centered nameAndIban">
    <div id="name">
      <p class="text--small little-padding">{{ name }}<br /></p>
      <p class="text--small text--bold little-padding">Privatkonten</p>
    </div>
    <span class="text--small text--bold bottom-row-mobile">{{ iban | ibanFormatter }}</span>
  </div>
</div>
