import { Component, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronRight, faPhone, faEnvelope, faGlobe } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'ez-contact',
  standalone: true,
  imports: [FontAwesomeModule],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss',
})
export class ContactComponent {
  @Input({ required: true }) logoUrl: string;
  @Input({ required: true }) companyName: string;
  @Input({ required: true }) companyAddress: {
    street: string;
    streetNumber: string;
    city: string;
    zipCode: string;
  };
  @Input() companyPhone: string;
  @Input() companyEmail: string;
  @Input() companyWeb: string;

  faChevronRight = faChevronRight;
  faPhone = faPhone;
  faEnvelope = faEnvelope;
  faGlobe = faGlobe;

  showPopupPanel = false;

  togglePopup() {
    this.showPopupPanel = !this.showPopupPanel;
  }
}
