<div class="info-popover">
  <!-- eslint-disable-next-line @angular-eslint/template/elements-content -->
  <button aria-label="mehr Informationen" type="button" (click)="toggleVisibility()" class="info-icon" #icon>
    <fa-icon [icon]="faCircleInfo" class="icon"></fa-icon>
  </button>
  <div class="popover-container" [style.visibility]="isHidden" [style.height]="this.popoverHeight">
    <div [style.visibility]="isHidden ? 'hidden' : 'visible'" class="popover" #popover>
      {{ popoverText }}
    </div>
  </div>
</div>
