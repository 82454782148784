@let _dzBankConfiguration = dzBankConfiguration()!;
@let _customData = customData()!;
@let _customer = customer()!;
@let _ibanAnonymised = ibanAnonymised()!;
@if (_dzBankConfiguration && _customData && _customer && _ibanAnonymised) {
  <ez-main-layout [theme]="Themes.NONE">
    <div class="content">
      <ez-stepper></ez-stepper>

      <div class="block--centered block--colored" id="creditCardOverview">
        <h1>Antrags&shy;übersicht</h1>
        <p>Um die Kreditkarte zu beantragen, benötigen wir weitere Informationen von Ihnen</p>
        <ez-credit-card-image></ez-credit-card-image>
        <p class="text--bold">Kreditkarte Gold</p>
        <p>
          <span>Der Preis der Kreditkarte Gold ({{ _dzBankConfiguration.cardType }}) beläuft sich jährlich</span>
          @if (
            _customData['cardPriceSecondYear'] ||
            _customData['cardPriceThirdYear'] ||
            _customData['cardPriceFourthYear']
          ) {
            <span>*</span>
          }
          <span> auf</span>
        </p>
        <p class="text--bold text--highlighted">
          {{ _dzBankConfiguration.cardPrice | currency: '€' : 'code' : '1.2-2' }}
        </p>
      </div>

      <div id="deliveryAddress" class="block--colored">
        <h4>Lieferadresse Ihrer Kreditkarte</h4>
        <p>
          {{ _customer.address.street }}, {{ _customer.address.postalCode }}
          {{ _customer.address.city }}
        </p>
      </div>

      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <div id="billingAccountInfo" class="block--colored">
          <h4>Abrechnungskonto Ihrer Kreditkarte</h4>

          <ez-bank-account-info
            [iban]="_ibanAnonymised"
            [name]="_customer.firstName + ' ' + _customer.lastName"
          ></ez-bank-account-info>

          <ez-expandable-checkbox
            controlName="isSepaMandateAccepted"
            label="SEPA-Lastschriftmandat erteilen"
            name="sepa-checkbox"
            [altText]="sepaText.alt"
          >
            <p class="text--small preview">{{ sepaText.preview }}</p>
            <p style="font-weight: normal">{{ sepaText.expanded }}</p>
          </ez-expandable-checkbox>
        </div>

        <div id="emailContainer" class="block--colored">
          <h4>E-Mail-Adresse</h4>
          <ez-email-input name="email" controlName="email"></ez-email-input>
          <span class="inline--padded">
            Sollten Sie Informationen zum Währungsumrechnungsentgelt
            <ez-info-popover [popoverText]="feeInfoText"></ez-info-popover>
            per SMS wünschen oder auf die Information verzichten wollen, können Sie sich jederzeit an unseren
            Kundensupport wenden.
          </span>
        </div>

        <div id="mandatoryDeclarations" class="block--colored">
          <h4>SCHUFA-Hinweis</h4>
          <ez-expandable
            header="Datenübermittlung an die SCHUFA und Befreiung vom Bankgeheimnis"
            headerFontWeight="normal"
          >
            <p class="text--small preview">{{ schufaText.preview }}</p>
            <p style="font-weight: normal">{{ schufaText.expanded }}</p>
          </ez-expandable>

          <h4>Erforderliche Erklärungen</h4>
          <ez-expandable-checkbox
            controlName="isBankInfoAccepted"
            label="Ermächtigung zur Erteilung einer Bankauskunft"
            name="bank-info"
            [altText]="bankInfoText.alt"
          >
            <p class="text--small preview">{{ bankInfoText.preview }}</p>
            <p style="font-weight: normal">{{ bankInfoText.expanded }}</p>
          </ez-expandable-checkbox>

          <ez-expandable-checkbox
            controlName="isMoneyLaunderingAccepted"
            label="Angabe nach §3 Geldwäsche&shy;gesetz"
            name="money-laundering"
            [altText]="moneyLaunderingText.alt"
          >
            <p class="text--small preview">{{ moneyLaunderingText.preview }}</p>
            <p style="font-weight: normal">{{ moneyLaunderingText.expanded }}</p>
          </ez-expandable-checkbox>
        </div>

        @if (
          _customData['cardPriceSecondYear'] || _customData['cardPriceThirdYear'] || _customData['cardPriceFourthYear']
        ) {
          <p class="text--very-small">
            *
            @if (_customData['cardPriceSecondYear']) {
              Ab dem zweiten Jahr beträgt der Preis der Kreditkarte
              {{ _customData['cardPriceSecondYear'] }} €.
            }
            @if (_customData['cardPriceThirdYear']) {
              Ab dem dritten Jahr beträgt der Preis der Kreditkarte
              {{ _customData['cardPriceThirdYear'] }} €.
            }
            @if (_customData['cardPriceFourthYear']) {
              Ab dem vierten Jahr beträgt der Preis der Kreditkarte
              {{ _customData['cardPriceFourthYear'] }} €.
            }
          </p>
        }

        <ez-button
          [disabled]="!(form && form.valid)"
          [loading]="submitEmailAndAcceptedFieldsRequestIsLoading"
          class="block--centered"
          label="Weiter"
          maxWidth="450px"
          altText="Wenn Sie das Sepa Mandat erteilt, die E-Mail-Adresse eingegeben und die erforderlichen Erklärungen abgegeben haben, klicken Sie hier auf das orangene Rechteck, um weiter zu kommen"
        ></ez-button>
      </form>
      <p>Mit dem Klicken des Buttons erhalten Sie einen persönlichen Verifizierungscode per E-Mail.</p>
    </div>
  </ez-main-layout>
}
