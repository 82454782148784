import { Component } from '@angular/core';

@Component({
  selector: 'ez-verification-error',
  standalone: true,
  imports: [],
  templateUrl: './verification-error.component.html',
  styleUrl: './verification-error.component.scss',
})
export class VerificationErrorComponent {}
