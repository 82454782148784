import { Component, OnInit, Signal } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { lastValueFrom } from 'rxjs';
import { BackendErrorType } from '../../shared/services/errors/backend.errors';
import { MainLayoutComponent } from '../../shared/components/layouts/main-layout/main-layout.component';
import { BannerComponent } from '../../shared/components/banner/banner.component';
import { ButtonComponent } from '../../shared/components/button/button.component';
import { BackendService } from 'app/shared/services/backend.service';
import { LandingpageConfigurationStateService } from '../../shared/services/state/landingpage-configuration-state.service';
import { StepperComponent } from '../../shared/components/stepper/stepper.component';
import { Organisation } from 'app/shared/models/organisation.model';
import { AuthenticationStateService } from 'app/shared/services/state/authentication-state.service';

@Component({
  selector: 'ez-authentication',
  standalone: true,
  imports: [ReactiveFormsModule, MainLayoutComponent, BannerComponent, ButtonComponent, StepperComponent],
  templateUrl: './authentication.component.html',
  styleUrl: './authentication.component.scss',
})
export class AuthenticationComponent implements OnInit {
  form = new FormGroup({
    password: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });
  submitAuthenticationTokenRequestIsLoading: boolean;
  organisation: Signal<Organisation | null>;

  constructor(
    private backendService: BackendService,
    private landingpageConfigurationStateService: LandingpageConfigurationStateService,
    private authenticationStateService: AuthenticationStateService,
    private router: Router,
    private logger: NGXLogger,
  ) {}

  ngOnInit(): void {
    this.organisation = this.landingpageConfigurationStateService.organisation;
  }

  get authInput(): AbstractControl {
    return this.form.controls.password;
  }

  hasError(): boolean {
    const authInput = this.authInput;
    return authInput && authInput.invalid && (authInput.dirty || authInput.touched);
  }

  getErrorMessage(): string {
    const authInput = this.authInput;

    if (authInput.errors?.['required']) {
      return 'Fehler: Dies ist ein Pflichtfeld. Bitte verwenden Sie die letzten vier Ziffern Ihrer IBAN.';
    } else if (authInput.errors?.[BackendErrorType.CLIENT_ERROR]) {
      return 'Fehler: Bitte verwenden Sie die letzten vier Ziffern Ihrer IBAN.';
    } else if (authInput.errors?.[BackendErrorType.SERVER_ERROR]) {
      return 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.';
    }
    return 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.';
  }

  async onSubmit() {
    const password = this.form.controls['password'].value;
    this.submitAuthenticationTokenRequestIsLoading = true;
    lastValueFrom(this.backendService.submitAuthenticationToken(password))
      .then((response) => {
        this.logger.debug('Authentication response:', {
          requestParam: password,
          response: response,
        });
        this.authenticationStateService.setPassword(password);
        this.landingpageConfigurationStateService.setLandingpageConfiguration(response);

        this.router.navigate(['overview']);
      })
      .catch((error) => {
        if (error.message === BackendErrorType.ALREADY_SENT) {
          this.router.navigate(['already-sent']);
        } else if (error.message === BackendErrorType.VERIFICATION_ERROR) {
          this.router.navigate(['verification-error']);
        } else if (error.message === BackendErrorType.SERVER_ERROR) {
          this.router.navigate(['error']);
        } else {
          this.authInput.setErrors({ [error.message]: true });
        }
      })
      .finally(() => {
        this.submitAuthenticationTokenRequestIsLoading = false;
      });
  }
}
