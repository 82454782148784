@let _organisation = organisation()!;
@if (_organisation) {
  <ez-main-layout>
    <ez-stepper></ez-stepper>

    <ez-banner>
      <span ngProjectAs="title">Willkommen bei Ihrer {{ _organisation.name }}</span>
      <span ngProjectAs="subtitle">Ihre Authentifizierung</span>
    </ez-banner>
    <p>
      Hallo, bitte authentifizieren Sie sich mit den letzten vier Ziffern Ihrer IBAN. Dieser Schritt dient der
      Sicherheit Ihrer Daten.
    </p>
    <form class="form-body" [formGroup]="form" (ngSubmit)="onSubmit()">
      <input
        aria-label="Fokussieren Sie das rechteckige Eingabefeld, um sich mit den letzten vier Ziffern Ihrer IBAN zu authentifizieren."
        type="password"
        autocomplete="cc-number"
        placeholder="Bitte die letzten vier Ziffern Ihrer IBAN eingeben"
        formControlName="password"
        [class.error]="hasError()"
      />
      @if (hasError()) {
        <span class="error-message">{{ getErrorMessage() }}</span>
      }
      <ez-button
        altText="Drücken Sie den rechteckigen, orangen Button unterhalb des Eingabefeldes zum Senden Ihres persönlichen Zugangscodes."
        maxWidth="100px"
        [disabled]="!(form && form.valid)"
        [loading]="submitAuthenticationTokenRequestIsLoading"
        label="Senden"
      ></ez-button>
    </form>
  </ez-main-layout>
}
