import { Component, OnInit, Signal } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { CustomData } from '../../shared/models/landingpage-configuration.model';
import { CardType, DzBankConfiguration } from '../../shared/models/dzbank-configuration.model';
import { ExpandableComponent } from '../../shared/components/expandable/expandable.component';
import { InfoPopoverComponent } from '../../shared/components/info-popover/info-popover.component';
import { ButtonComponent } from '../../shared/components/button/button.component';
import { MainLayoutComponent } from '../../shared/components/layouts/main-layout/main-layout.component';
import { Themes } from '../../../themes';
import { ExpandableCheckboxComponent } from '../../shared/components/expandable-checkbox/expandable-checkbox.component';
import {
  bankInfoText,
  feeInfoText,
  moneyLaunderingText,
  schufaText,
  sepaText,
} from '../../shared/models/expandable-texts';
import { BankAccountInfoComponent } from '../../shared/components/bank-account-info/bank-account-info.component';
import { EmailInputComponent } from '../../shared/components/email-input/email-input.component';
import { emailValidator } from '../../shared/components/email-input/email-input.validator';
import { BackendService } from '../../shared/services/backend.service';
import { MandatoryAcceptances } from '../../shared/models/dto/email-request-dto';
import { StepperComponent } from '../../shared/components/stepper/stepper.component';
import { CreditCardImageComponent } from '../../shared/components/credit-card-image/credit-card-image.component';
import { lastValueFrom } from 'rxjs';
import { BackendErrorType } from 'app/shared/services/errors/backend.errors';
import { LandingpageConfigurationStateService } from 'app/shared/services/state/landingpage-configuration-state.service';
import { Customer } from 'app/shared/models/customer.model';

@Component({
  selector: 'ez-overview',
  standalone: true,
  templateUrl: './overview.component.html',
  styleUrl: './overview.component.scss',
  imports: [
    MainLayoutComponent,
    CurrencyPipe,
    ExpandableComponent,
    ExpandableComponent,
    ReactiveFormsModule,
    InfoPopoverComponent,
    ButtonComponent,
    MainLayoutComponent,
    ExpandableCheckboxComponent,
    BankAccountInfoComponent,
    EmailInputComponent,
    StepperComponent,
    CreditCardImageComponent,
  ],
  providers: [],
})
export class OverviewComponent implements OnInit {
  form: FormGroup;
  submitEmailAndAcceptedFieldsRequestIsLoading: boolean;
  customData: Signal<CustomData | null>;
  dzBankConfiguration: Signal<DzBankConfiguration | null>;
  customer: Signal<Customer | null>;
  ibanAnonymised: Signal<string | null>;

  protected readonly CardType = CardType;
  protected readonly Themes = Themes;
  protected readonly feeInfoText = feeInfoText;
  protected readonly bankInfoText = bankInfoText;
  protected readonly schufaText = schufaText;
  protected readonly moneyLaunderingText = moneyLaunderingText;
  protected readonly sepaText = sepaText;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private backendService: BackendService,
    private landingpageConfigurationStateService: LandingpageConfigurationStateService,
  ) {}

  ngOnInit(): void {
    this.customData = this.landingpageConfigurationStateService.customData;
    this.customer = this.landingpageConfigurationStateService.customer;
    this.dzBankConfiguration = this.landingpageConfigurationStateService.dzBankConfiguration;
    this.ibanAnonymised = this.landingpageConfigurationStateService.ibanAnonymised;

    this.form = this.formBuilder.group({
      isSepaMandateAccepted: [false, Validators.requiredTrue],
      isMoneyLaunderingAccepted: [false, Validators.requiredTrue],
      isBankInfoAccepted: [false, Validators.requiredTrue],
      email: ['', [Validators.required, emailValidator]],
    });
  }
  onSubmit() {
    const acceptances: MandatoryAcceptances = {
      isBankInfoAccepted: this.form.value['isBankInfoAccepted'],
      isMoneyLaunderingAccepted: this.form.value['isMoneyLaunderingAccepted'],
      isSepaMandateAccepted: this.form.value['isSepaMandateAccepted'],
    };

    this.submitEmailAndAcceptedFieldsRequestIsLoading = true;
    const email = this.form.value['email'];
    lastValueFrom(this.backendService.submitEmailAndAcceptedFields(email, acceptances))
      .then(() => {
        this.landingpageConfigurationStateService.setCustomerEmail(email);
        this.router.navigate(['verify-email']);
      })
      .catch((error) => {
        if (error.message === BackendErrorType.ALREADY_SENT) {
          this.router.navigate(['already-sent']);
        } else if (error.message === BackendErrorType.VERIFICATION_ERROR) {
          this.router.navigate(['verification-error']);
        } else if (error.message === BackendErrorType.SERVER_ERROR) {
          this.router.navigate(['error']);
        }
      })
      .finally(() => {
        this.submitEmailAndAcceptedFieldsRequestIsLoading = false;
      });
  }
}
