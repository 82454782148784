import { Component } from '@angular/core';

@Component({
  selector: 'lib-eazi-angular-library',
  standalone: true,
  imports: [],
  template: ` <p>eazi-angular-library works!</p> `,
  styles: ``,
})
export class EaziAngularLibraryComponent {}
