import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { parseBackendErrorResponse } from './errors/backend.errors';
import { environment } from '@environment/environment';
import { MandatoryAcceptances, EmailRequestDTO } from '../models/dto/email-request-dto';
import { LandingpageConfigurationResponseDTO } from '../models/dto/landingpage-configuration-response-dto';
import { OrganisationResponseDTO } from '../models/dto/organisation-response-dto';
import { VerifyEmailRequestDTO } from '../models/dto/verify-email-request-dto';
import { OrderRequestDTO } from '../models/dto/order-request-dto';
import { AuthenticationStateService } from './state/authentication-state.service';

/**
 * BackendService is a service class that provides methods for interacting with the backend API.
 */
@Injectable({
  providedIn: 'root',
})
export class BackendService {
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private authenticationStateService: AuthenticationStateService,
  ) {}

  /**
   * submitPersonalAccessToken is a method that sends a personal access token to the backend API.
   * @param personalAccessToken - External case identifier.
   */
  submitPersonalAccessToken(personalAccessToken: string) {
    return this.http
      .get<OrganisationResponseDTO>(`${environment.apiURL}/dzbank/landingpage/${personalAccessToken}/organisation`)
      .pipe(catchError(this.httpErrorHandler.bind(this)));
  }

  /**
   * submitAuthenticationToken is a method that sends an authentication token to the backend API.
   * @param personalAccessToken - External case identifier.
   * @param authToken - The authentication token - this corresponds to the anonymised IBAN.
   */
  submitAuthenticationToken(authToken: string) {
    const personalAccessToken = this.authenticationStateService.personalAccessToken()!; // existence of personalAccessToken should be verified by canActivate guard
    const params = new HttpParams().append('password', authToken);
    return this.http
      .get<LandingpageConfigurationResponseDTO>(`${environment.apiURL}/dzbank/landingpage/${personalAccessToken}`, {
        params,
      })
      .pipe(catchError(this.httpErrorHandler.bind(this)));
  }

  /**
   *Sends the form values of the overview view to core backend.
   *
   * @param email the customer's e-mail. A verify code will be sent to this address
   * @param acceptances the boolean values of the mandatory checkboxes on the overview page
   */
  submitEmailAndAcceptedFields(email: string, acceptances: MandatoryAcceptances) {
    const personalAccessToken = this.authenticationStateService.personalAccessToken()!; // existence of personalAccessToken should be verified by canActivate guard
    const password = this.authenticationStateService.password()!; // existence of password should be verified by canActivate guard

    const emailRequestDTO: EmailRequestDTO = {
      recipientEmailAddress: email,
      lastFourIbanDigits: password,
      ...acceptances,
    };
    return this.http
      .post<void>(`${environment.apiURL}/dzbank/landingpage/${personalAccessToken}/mail`, emailRequestDTO)
      .pipe(catchError(this.httpErrorHandler.bind(this)));
  }

  verifyEmailCode(emailVerificationCode: string) {
    const personalAccessToken = this.authenticationStateService.personalAccessToken()!; // existence of personalAccessToken should be verified by canActivate guard
    const password = this.authenticationStateService.password()!; // existence of password should be verified by canActivate guard

    const verifyEmailRequestDTO: VerifyEmailRequestDTO = {
      lastFourIbanDigits: password,
      verificationCode: emailVerificationCode,
    };
    return this.http
      .post<void>(
        `${environment.apiURL}/dzbank/landingpage/${personalAccessToken}/verify-mail-code`,
        verifyEmailRequestDTO,
      )
      .pipe(catchError(this.httpErrorHandler.bind(this)));
  }

  /**
   * Sends a POST Request to the backend which triggers the completion of the case
   * and the order of the creditcard
   */
  orderCreditCard() {
    const personalAccessToken = this.authenticationStateService.personalAccessToken()!; // existence of personalAccessToken should be verified by canActivate guard
    const password = this.authenticationStateService.password()!; // existence of password should be verified by canActivate guard
    const orderRequestDTO: OrderRequestDTO = {
      lastFourIbanDigits: password,
    };
    return this.http
      .post(`${environment.apiURL}/dzbank/landingpage/${personalAccessToken}/order`, orderRequestDTO)
      .pipe(catchError(this.httpErrorHandler.bind(this)));
  }

  /**
   * Handles HTTP errors occurred in backend service.
   *
   * @param response - The HttpErrorResponse that occurred.
   * @returns Observable that emits an Error object with custom error message.
   */
  private httpErrorHandler(response: HttpErrorResponse) {
    this.logger.error(response);
    const backendErrorType = parseBackendErrorResponse(response.error, response.status);
    return throwError(() => new Error(backendErrorType));
  }
}
