import { HttpStatusCode } from '@angular/common/http';
import { BackendErrorDto } from '../../models/dto/backend-error-dto';

export enum BackendErrorType {
  ALREADY_SENT = 'ALREADY_SENT', //maps to CaseClosedException
  VERIFICATION_ERROR = 'VERIFICATION_ERROR',
  SERVER_ERROR = 'SERVER_ERROR',
  CLIENT_ERROR = 'CLIENT_ERROR',
  EMAIL_NOT_VERIFIED = 'UNVERIFIED_EMAIL',
  WRONG_MAIL_CODE = 'WRONG_MAIL_CODE',
}

enum CaseClosedReason {
  UNKNOWN = 'UNKNOWN',
  CASE_EXPIRED = 'CASE_EXPIRED',
  CREDITCARD_ORDERED = 'CREDITCARD_ORDERED',
}

export function parseBackendErrorResponse(error: BackendErrorDto, status: number): BackendErrorType {
  const errorStatus = status || -1;
  const errorMessage = error.message;

  if (errorStatus == HttpStatusCode.BadRequest && errorMessage.includes('was already closed')) {
    if (error.details === CaseClosedReason.CASE_EXPIRED || error.details === CaseClosedReason.UNKNOWN) {
      return BackendErrorType.VERIFICATION_ERROR;
    } else {
      return BackendErrorType.ALREADY_SENT;
    }
  }

  if (errorStatus == HttpStatusCode.BadRequest && errorMessage.includes('Email verification not')) {
    return BackendErrorType.EMAIL_NOT_VERIFIED;
  }

  if (errorStatus == HttpStatusCode.BadRequest && errorMessage.includes('Invalid email verification code')) {
    return BackendErrorType.WRONG_MAIL_CODE;
  }

  if (errorStatus == HttpStatusCode.BadRequest && errorMessage.includes('The landingpage password is invalid')) {
    return BackendErrorType.VERIFICATION_ERROR;
  }

  if (
    errorStatus == HttpStatusCode.BadRequest &&
    errorMessage.includes('Case with personal access token') &&
    errorMessage.includes('does not exist')
  ) {
    return BackendErrorType.VERIFICATION_ERROR;
  }

  if (errorStatus >= 400 && errorStatus <= 499) {
    return BackendErrorType.CLIENT_ERROR;
  } else if (errorStatus >= 500 && errorStatus <= 599) {
    return BackendErrorType.SERVER_ERROR;
  }

  return BackendErrorType.VERIFICATION_ERROR;
}
