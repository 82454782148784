<button
  [type]="buttonType"
  class="btn btn-{{ theme }}"
  [style.maxWidth]="maxWidth"
  (click)="onClickButton($event)"
  [disabled]="disabled || loading"
  [attr.aria-label]="altText"
>
  @if (loading) {
    <fa-icon class="fa-icon" [icon]="faSpinner" title="" animation="spin"></fa-icon>
  }
  <span class="btn-label">{{ label }}</span>
</button>
