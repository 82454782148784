<div class="contact-panel">
  @if (showPopupPanel) {
    <div class="popup-panel">
      <div class="popup-panel-inlay">
        <img alt="Company Logo" class="popup-logo" decoding="sync" [src]="logoUrl" />
        <div class="addressBlock">
          <span>{{ companyName }}</span>
          <span>{{ companyAddress.street }} {{ companyAddress.streetNumber }}</span>
          <span>{{ companyAddress.zipCode }} {{ companyAddress.city }}</span>
        </div>
        <table class="communication">
          @if (companyPhone) {
            <tr>
              <td><fa-icon [icon]="faPhone"></fa-icon></td>
              <td><span>Telefon:</span></td>
              <td>
                <a class="contactLink" href="{{ 'tel:' + companyPhone }}">{{ companyPhone }}</a>
              </td>
            </tr>
          }
          @if (companyEmail) {
            <tr>
              <td><fa-icon [icon]="faEnvelope"></fa-icon></td>
              <td><span>E-Mail:</span></td>
              <td>
                <a class="contactLink" href="{{ 'mailto:' + companyEmail }}">{{ companyEmail }}</a>
              </td>
            </tr>
          }
          @if (companyWeb) {
            <tr>
              <td><fa-icon [icon]="faGlobe"></fa-icon></td>
              <td><span>Webseite:</span></td>
              <td>
                <a class="contactLink" href="{{ companyWeb }}" target="_blank">{{ companyWeb }}</a>
              </td>
            </tr>
          }
        </table>
      </div>
    </div>
  }
  <button aria-label="Ihr Kontakt" type="button" class="contact-button" (click)="togglePopup()">
    <fa-icon [icon]="faChevronRight" transform="rotate-{{ showPopupPanel ? -90 : 0 }}"></fa-icon>
    <img alt="Company Logo" class="button-logo" decoding="sync" [src]="logoUrl" />
    <div class="text-container">
      <p>Sie haben Fragen?</p>
      <p><b>Ihr Kontakt</b></p>
    </div>
  </button>
</div>
