import { effect, Injectable, signal, untracked } from '@angular/core';
import { CustomData, LandingpageConfiguration } from 'app/shared/models/landingpage-configuration.model';
import { Organisation } from 'app/shared/models/organisation.model';
import { DzBankConfiguration } from 'app/shared/models/dzbank-configuration.model';
import { Customer } from 'app/shared/models/customer.model';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationStateService } from './authentication-state.service';
import { BackendService } from '../backend.service';

@Injectable({
  providedIn: 'root',
})
export class LandingpageConfigurationStateService {
  private organisationSignal = signal<Organisation | null>(null);
  private dzBankConfigurationSignal = signal<DzBankConfiguration | null>(null);
  private customerSignal = signal<Customer | null>(null);
  private customDataSignal = signal<CustomData | null>(null);
  private ibanAnonymisedSignal = signal<string | null>(null);

  readonly organisation = this.organisationSignal.asReadonly();
  readonly dzBankConfiguration = this.dzBankConfigurationSignal.asReadonly();
  readonly customer = this.customerSignal.asReadonly();
  readonly customData = this.customDataSignal.asReadonly();
  readonly ibanAnonymised = this.ibanAnonymisedSignal.asReadonly();

  constructor(
    private router: Router,
    private authenticationStateService: AuthenticationStateService,
    private backendService: BackendService,
  ) {
    effect(() => {
      // Effect to load landingpageConfiguration
      const personalAccessToken = this.authenticationStateService.personalAccessToken();
      const password = this.authenticationStateService.password();

      untracked(() => {
        if (
          personalAccessToken &&
          password &&
          !this.dzBankConfigurationSignal() &&
          !this.customerSignal() &&
          !this.customDataSignal() &&
          !this.ibanAnonymisedSignal()
        ) {
          this.getAndSetLandingpageConfigurationFromBackend(password);
        } else if (
          personalAccessToken &&
          !password &&
          !this.dzBankConfigurationSignal() &&
          !this.customerSignal() &&
          !this.customDataSignal() &&
          !this.ibanAnonymisedSignal() &&
          !this.organisationSignal()
        ) {
          this.getAndSetOrganisationFromBackend(personalAccessToken);
        }
      });
    });
  }

  setOrganisation(value: Organisation) {
    this.organisationSignal.set(value);
  }

  setLandingpageConfiguration(value: LandingpageConfiguration) {
    this.organisationSignal.set(value.organisation);
    this.dzBankConfigurationSignal.set(value.dzBankConfiguration);
    this.customerSignal.set(value.customer);
    this.customDataSignal.set(value.customData);
    this.ibanAnonymisedSignal.set(value.ibanAnonymised);
  }

  setCustomerEmail(email: string) {
    this.customerSignal.update((customer) => {
      if (customer) customer.email = email;
      return customer;
    });
  }

  private getAndSetLandingpageConfigurationFromBackend(password: string) {
    lastValueFrom(this.backendService.submitAuthenticationToken(password))
      .then((response) => {
        this.setLandingpageConfiguration(response);
      })
      .catch(() => {
        this.handleErrorOnBackendRequest();
      });
  }

  private getAndSetOrganisationFromBackend(personalAccessToken: string) {
    lastValueFrom(this.backendService.submitPersonalAccessToken(personalAccessToken))
      .then((response) => {
        this.setOrganisation(response.organisation);
      })
      .catch(() => {
        this.handleErrorOnBackendRequest();
      });
  }

  private handleErrorOnBackendRequest() {
    sessionStorage.clear();
    this.router.navigate(['/']);
  }
}
