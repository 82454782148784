import { Component, OnInit, Signal } from '@angular/core';
import { BannerComponent } from 'app/shared/components/banner/banner.component';
import { MainLayoutComponent } from 'app/shared/components/layouts/main-layout/main-layout.component';
import { StepperComponent } from 'app/shared/components/stepper/stepper.component';
import { Organisation } from 'app/shared/models/organisation.model';
import { LandingpageConfigurationStateService } from 'app/shared/services/state/landingpage-configuration-state.service';
import { Themes } from 'themes';

@Component({
  selector: 'ez-completion',
  standalone: true,
  imports: [MainLayoutComponent, BannerComponent, StepperComponent],
  templateUrl: './completion.component.html',
  styleUrl: './completion.component.scss',
})
export class CompletionComponent implements OnInit {
  organisation: Signal<Organisation | null>;

  protected readonly Themes = Themes;

  constructor(private landingpageConfigurationStateService: LandingpageConfigurationStateService) {}

  ngOnInit(): void {
    this.organisation = this.landingpageConfigurationStateService.organisation;
  }
}
