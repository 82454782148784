import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

type ButtonTheme = 'primary' | 'secondary';
type ButtonType = 'button' | 'submit';

@Component({
  selector: 'ez-button',
  standalone: true,
  imports: [FaIconComponent],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  @Input() label: string;
  @Input() altText: string;
  @Input() theme: ButtonTheme = 'primary';
  @Input() buttonType: ButtonType = 'submit';
  @Input() disabled = false;
  @Input() maxWidth = '100%';
  @Input() loading = false;
  @Output() buttonClick = new EventEmitter<MouseEvent>();

  onClickButton(event: MouseEvent) {
    this.buttonClick.emit(event);
  }

  protected readonly faSpinner = faSpinner;
}
