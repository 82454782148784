import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'ez-checkbox-input',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './checkbox-input.component.html',
  styleUrl: './checkbox-input.component.scss',
})
export class CheckboxInputComponent implements OnInit, OnChanges {
  @Input() isDisabled = false;
  @Input() name: string;
  @Input() controlName: string;
  @Input() altText: string;
  @Input() isContentVisible = true;
  @ViewChild('checkbox') input: ElementRef<HTMLInputElement>;
  form: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective) {}

  get control(): AbstractControl {
    return this.form.get(this.controlName)!;
  }

  hasError(): boolean {
    const control = this.control;
    return control && control.invalid && (control.dirty || control.touched);
  }

  getErrorMessage(): string {
    const control = this.control;
    if (control.errors?.['required']) {
      return 'Fehler: Dies ist ein Pflichtfeld.';
    }
    return 'Fehlerhafte Eingabe.';
  }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control;
    this.updateDisabled();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isDisabled']) {
      this.updateDisabled();
    }
  }

  updateDisabled(): void {
    const control = this.control;
    if (this.isDisabled) {
      control.disable();
    } else {
      control.enable();
    }
  }
}
