import { Component, OnInit, Signal } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { BannerComponent } from 'app/shared/components/banner/banner.component';
import { ButtonComponent } from 'app/shared/components/button/button.component';
import { MainLayoutComponent } from 'app/shared/components/layouts/main-layout/main-layout.component';
import { StepperComponent } from 'app/shared/components/stepper/stepper.component';
import { Organisation } from 'app/shared/models/organisation.model';
import { Themes } from 'themes';
import { BackendService } from 'app/shared/services/backend.service';
import { LandingpageConfigurationStateService } from 'app/shared/services/state/landingpage-configuration-state.service';
import { BackendErrorType } from '../../shared/services/errors/backend.errors';

@Component({
  selector: 'ez-verify-email',
  standalone: true,
  imports: [MainLayoutComponent, BannerComponent, ButtonComponent, ReactiveFormsModule, StepperComponent],
  templateUrl: './verify-email.component.html',
  styleUrl: './verify-email.component.scss',
})
export class VerifyEmailComponent implements OnInit {
  organisation: Signal<Organisation | null>;
  form: FormGroup;
  verifyEmailCodeRequestIsLoading: boolean;

  protected readonly Themes = Themes;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private backendService: BackendService,
    private landingpageConfigurationStateService: LandingpageConfigurationStateService,
  ) {}

  ngOnInit(): void {
    this.organisation = this.landingpageConfigurationStateService.organisation;

    this.form = this.formBuilder.group({
      emailVerificationCode: [
        null,
        {
          validators: [Validators.required],
        },
      ],
    });
  }

  get emailVerificationCodeControl(): AbstractControl {
    return this.form.get('emailVerificationCode')!;
  }

  async onSubmit() {
    const emailVerificationCode = this.emailVerificationCodeControl.value;
    this.verifyEmailCodeRequestIsLoading = true;
    lastValueFrom(this.backendService.verifyEmailCode(emailVerificationCode))
      .then(() => {
        this.router.navigate(['summary']);
      })
      .catch((error) => {
        if (error.message === BackendErrorType.ALREADY_SENT) {
          this.router.navigate(['already-sent']);
        } else if (error.message === BackendErrorType.VERIFICATION_ERROR) {
          this.router.navigate(['verification-error']);
        } else if (error.message === BackendErrorType.SERVER_ERROR) {
          this.router.navigate(['error']);
        } else if (error.message === BackendErrorType.WRONG_MAIL_CODE) {
          this.emailVerificationCodeControl.setErrors({ codeMismatch: true });
        }
      })
      .finally(() => {
        this.verifyEmailCodeRequestIsLoading = false;
      });
  }
}
