@let _organisation = organisation()!;
@let _dzBankConfiguration = dzBankConfiguration()!;
@if (_organisation.corporateDesign.logos.customLogos['creditCard']) {
  <img
    class="img--mobile-compatible creditCardImg"
    [src]="_organisation.corporateDesign.logos.customLogos['creditCard']"
    alt="Bild Ihrer möglichen neuen Kreditkarte Gold"
  />
} @else if (_dzBankConfiguration.cardType === CardType.VisaCard) {
  <img
    class="img--mobile-compatible creditCardImg"
    src="https://acruvglobalassetstack-assetbucket1d025086-18mcf4ueedojk.s3.eu-central-1.amazonaws.com/campaign-assets/dz-bank/logos/VS_Visa_Gold.png"
    alt="Bild Ihrer möglichen neuen {{ CardType.VisaCard }}"
  />
} @else {
  <img
    class="img--mobile-compatible creditCardImg"
    src="https://acruvglobalassetstack-assetbucket1d025086-18mcf4ueedojk.s3.eu-central-1.amazonaws.com/campaign-assets/dz-bank/logos/VS_Mastercard_Gold.png"
    alt="Bild Ihrer möglichen neuen {{ CardType.MasterCard }}"
  />
}
