import { Component, OnInit, Signal } from '@angular/core';
import { MainLayoutComponent } from '../../shared/components/layouts/main-layout/main-layout.component';
import { StepperComponent } from '../../shared/components/stepper/stepper.component';
import { CreditCardImageComponent } from '../../shared/components/credit-card-image/credit-card-image.component';
import { Router } from '@angular/router';
import { Themes } from '../../../themes';
import { ButtonComponent } from '../../shared/components/button/button.component';
import { BackendService } from '../../shared/services/backend.service';
import { CurrencyPipe } from '@angular/common';
import { IbanFormatterPipe } from '../../shared/pipes/iban-formatter/iban-formatter.pipe';
import { lastValueFrom } from 'rxjs';
import { LandingpageConfigurationStateService } from 'app/shared/services/state/landingpage-configuration-state.service';
import { Customer } from 'app/shared/models/customer.model';
import { DzBankConfiguration } from 'app/shared/models/dzbank-configuration.model';
import { Organisation } from 'app/shared/models/organisation.model';
import { BackendErrorType } from '../../shared/services/errors/backend.errors';

@Component({
  selector: 'ez-summary',
  standalone: true,
  imports: [
    MainLayoutComponent,
    StepperComponent,
    CreditCardImageComponent,
    ButtonComponent,
    CurrencyPipe,
    IbanFormatterPipe,
  ],
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.scss',
})
export class SummaryComponent implements OnInit {
  orderCreditCardRequestIsLoading: boolean;
  organisation: Signal<Organisation | null>;
  dzBankConfiguration: Signal<DzBankConfiguration | null>;
  customer: Signal<Customer | null>;
  ibanAnonymised: Signal<string | null>;

  protected readonly Themes = Themes;

  constructor(
    private router: Router,
    private landingpageConfigurationStateService: LandingpageConfigurationStateService,
    private backendService: BackendService,
  ) {}

  ngOnInit(): void {
    this.organisation = this.landingpageConfigurationStateService.organisation;
    this.customer = this.landingpageConfigurationStateService.customer;
    this.dzBankConfiguration = this.landingpageConfigurationStateService.dzBankConfiguration;
    this.ibanAnonymised = this.landingpageConfigurationStateService.ibanAnonymised;
  }

  onSubmit() {
    this.orderCreditCardRequestIsLoading = true;
    lastValueFrom(this.backendService.orderCreditCard())
      .then(() => {
        this.router.navigate(['completion']);
      })
      .catch((error) => {
        if (error.message === BackendErrorType.ALREADY_SENT) {
          this.router.navigate(['already-sent']);
        } else if (error.message === BackendErrorType.VERIFICATION_ERROR) {
          this.router.navigate(['verification-error']);
        } else if (error.message === BackendErrorType.SERVER_ERROR) {
          this.router.navigate(['error']);
        }
      })
      .finally(() => {
        this.orderCreditCardRequestIsLoading = false;
      });
  }
}
