<div class="bottom">
  <div class="left top">
    <img
      class="logo"
      [src]="imageData.imgSrc"
      [attr.width]="imageData.imgWidth"
      [attr.height]="imageData.imgHeight"
      alt="Logo der {{ organisationName }}"
    />
  </div>
  <div class="right stack">
    <a class="imprint" target="_blank" rel="noopener noreferrer" [href]="imprintUrl">Impressum</a>
    <a class="dataPrivacy" target="_blank" rel="noopener noreferrer" [href]="dataPrivacyUrl">Datenschutz</a>
  </div>
</div>
