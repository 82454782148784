import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { provideHttpClient } from '@angular/common/http';
import { LoggerModule } from 'ngx-logger';
import { routes } from './app.routes';
import { environment } from '@environment/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(),
    importProvidersFrom(LoggerModule.forRoot({ level: environment.logLevel })),
  ],
};
