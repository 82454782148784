<details (toggle)="onToggle()" #details>
  <summary #summary role="button">
    <div class="summary-text">
      <span [style.font-weight]="headerFontWeight">{{ header }}</span>
      @if (!details.open) {
        <ng-content select=".preview"></ng-content>
      }
    </div>
    <fa-icon class="fa-icon" [icon]="details.open ? faChevronUp : faChevronDown" title="arrow"></fa-icon>
  </summary>
  <div>
    <ng-content></ng-content>
  </div>
</details>
