import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'ez-info-popover',
  standalone: true,
  imports: [FaIconComponent],
  templateUrl: './info-popover.component.html',
  styleUrl: './info-popover.component.scss',
})
export class InfoPopoverComponent implements AfterViewInit {
  @Input({ required: true }) popoverText: string;
  @ViewChild('popover') popover: ElementRef<HTMLElement>;
  @ViewChild('icon') icon: ElementRef<HTMLElement>;
  hostHeight: number;
  popoverHeight = '0px';
  isHidden = true;
  visibility = 'hidden';

  constructor(private host: ElementRef) {}

  ngAfterViewInit() {
    this.hostHeight = this.host.nativeElement.offsetHeight;
  }
  toggleVisibility() {
    this.isHidden = !this.isHidden;
    this.popoverHeight = this.isHidden ? '0px' : this.popoverHeight;
  }

  protected readonly faCircleInfo = faCircleInfo;
}
