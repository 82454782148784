<ez-main-layout>
  <ez-banner>
    <span ngProjectAs="title">Willkommen</span>
    <span ngProjectAs="subtitle">Ihr persönlicher Zugangscode</span>
  </ez-banner>
  <p>Bitte geben Sie im nachfolgenden Feld Ihren persönlichen Zugangscode aus dem Anschreiben ein.</p>
  <form class="form-body" [formGroup]="form" (ngSubmit)="onSubmit()">
    <input
      aria-label="Fokussieren Sie das rechteckige Eingabefeld, um Ihren persönlichen Zugangscode einzugeben."
      type="password"
      placeholder="Bitte Zugangscode eingeben"
      formControlName="personalAccessToken"
      [class.error]="hasError()"
    />
    @if (hasError()) {
      <span class="error-message">{{ getErrorMessage() }}</span>
    }
    <ez-button
      [disabled]="!(form && form.valid)"
      [loading]="submitPersonalAccessTokenIsLoading"
      ariaLabel="Drücken Sie den rechteckigen, orangen Button unterhalb des Eingabefeldes zum Senden Ihres persönlichen Zugangscodes."
      class="submit-button"
      maxWidth="100px"
      label="Senden"
    ></ez-button>
  </form>
</ez-main-layout>
